<template>
  <div id="app">
    <FeedBackForm v-show="!isShowSuccessScreen" v-on:show:success:screen="showSuccessScreen"/>
    <SuccessScreen v-show="isShowSuccessScreen"/>
  </div>
</template>

<script>
import FeedBackForm from './components/FeedBackForm.vue';
import SuccessScreen from './components/SuccessScreen';

export default {
  data() {
    return {
      isShowSuccessScreen: false,
    }
  },
  methods: {
    showSuccessScreen() {
      this.isShowSuccessScreen = true;

      setTimeout(() => {
        this.isShowSuccessScreen = false;
      }, 5000);
    }
  },
  components: {
    SuccessScreen,
    FeedBackForm
  }
}
</script>
