<!--http://localhost:8080/?userId=17955984&rand=a&session=306f3e82465eb77a353561933799eda8&platform=iOS&platform_version=5.35.25-->
<template>
  <div class="feedback-form">
    <h2 class="title">Напишите нам</h2>
    <span class="desc">Напишите нам, если у вас возникли вопросы, проблемы или предложения</span>
    <div class="support-form">
      <div class="form--field-group">
        <label for="email"></label>
        <input
            class="field-email"
            type="text"
            id="email"
            placeholder="Ваш email"
            v-model="form.email"
            :disabled="sending"
            @input="onInputChange"
            @blur="form.email=form.email.trim()"
        >
        <div class="errors-block" v-show="validations.isFormErrors">
          <span class="error" v-if="!$v.form.email.required">Поле обязательно для заполнения!</span>
          <span class="error" v-else-if="!$v.form.email.email">Email указан некорректно</span>
        </div>
      </div>

      <div class="form--field-group">
        <label for="content"></label>
        <textarea
            class="field-content"
            name="content"
            id="content"
            cols="30" rows="10"
            placeholder="Текст обращения"
            v-model="form.content"
            :disabled="sending"
            @input="onInputChange"
        ></textarea>
        <div class="errors-block errors-block--content" v-show="validations.isFormErrors">
          <span class="error" v-if="!$v.form.content.required">Поле обязательно для заполнения!</span>
          <span class="error" v-else-if="!$v.form.content.minLength">Недопустимая длина сообщения!</span>
        </div>
      </div>

      <div class="files">
        <div class="file" v-for="file in files" :key="file.id" :id="file.id">
          <div class="file--info-block">
            <span class="file--filename">{{ file.name }}</span>
            <span class="file--size">({{ file.size }}KB)</span>
          </div>
          <img
              src="../assets/img/bar_close_small.svg"
              class="iconapp-barclosesmall"
              alt="close"
              @click="deleteFile(file.id)"
          >
        </div>
        <div class="errors-block" v-show="validations.commonErrors.isError">
          <span class="error file-error" v-if="validations.commonErrors.isFileTypeError">Неверный формат файла!</span>
          <span class="error file-error" v-if="validations.commonErrors.isIncorrectEmail">Неверно указан email!</span>
          <span class="error file-error" v-else-if="validations.commonErrors.isEmptyUserParameters">Ошибка авторизации! Перейдите из приложения на форму обращения ещё раз!</span>
          <span class="error file-error" v-else-if="validations.commonErrors.isBadResponse">Ошибка отправки запроса! Попробуйте ещё раз!</span>
        </div>
      </div>

      <label for="file-attach" class="field-attach">
        <img src="../assets/img/icons_attach_normal.svg" alt="add attach" class="field-attach--icon">
        <span class="field-attach--text">Прикрепить</span>
        <input type="file" id="file-attach" @change="onFileChange" name="attachments[]">
      </label>

      <button class="field-send" :class="form.btnSendReady ? 'field-send--ready' : ''" @click="validateUser">Отправить</button>
    </div>
  </div>
</template>

<script>
import {validationMixin} from 'vuelidate';
import {
  required,
  minLength,
  email
} from 'vuelidate/lib/validators';
import config from '../config/common';
import axios from 'axios';
import _ from 'lodash';

export default {
  async created() {
    let queries = (function (queries) {
      if ("" === queries) return {};
      let b = {};
      for (var i = 0; i < queries.length; ++i) {
        let pair = queries[i].split('=', 2);
        if (pair.length === 1)
          b[pair[0]] = "";
        else
          b[pair[0]] = decodeURIComponent(pair[1].replace(/\+/g, " "));
      }
      return b;
    })(window.location.search.substr(1).split('&'));
    this.queries = queries;

    if (queries['email'] !== undefined && queries['email'] !== '') {
      this.form.email = queries['email'];
    }
  },
  mixins: [validationMixin],
  data: () => ({
    queries: null,
    form: {
      email: null,
      content: null,
      btnSendReady: false,
    },
    user: {
      id: null,
      platform: null,
      platform_version: null,
      rand: null,
      session: null,
    },
    sending: false,
    files: [],
    validations: {
      isFormErrors: false,
      commonErrors: {
        isFileTypeError: false,
        isEmptyUserParameters: false,
        isIncorrectEmail: false,
        isBadResponse: false,
        isError: false,
      }
    }
  }),
  validations: {
    form: {
      email: {
        required,
        email,
      },
      content: {
        required,
        minLength: minLength(5)
      },
    }
  },
  methods: {
    clearForm() {
      this.$v.$reset()
      this.form.email = null
      this.form.content = null
      this.files = [];
    },
    validateUser() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.validations.isFormErrors = true;
      } else {
        // валидация email
        if (-1 !== this.form.email.indexOf("rosfines.ru")) {
          this.validations.commonErrors.isError = true;
          this.validations.commonErrors.isIncorrectEmail = true;
        } else {
          this.createIssue();
        }
      }
    },
    async createIssue() {
      this.validations.commonErrors.isError = false;
      this.validations.commonErrors.isEmptyUserParameters = false;

      if (this.queries['userId'] === undefined || this.queries['userId'] === ''
          || this.queries['rand'] === undefined || this.queries['rand'] === ''
          || this.queries['session'] === undefined || this.queries['session'] === '') {
        this.validations.commonErrors.isError = true;
        this.validations.commonErrors.isEmptyUserParameters = true;

        return false;
      }

      this.user.id = this.queries['userId'];
      this.user.rand = this.queries['rand'];
      this.user.session = this.queries['session'];
      this.user.platform = this.queries['platform'];
      this.user.platform_version = this.queries['platform_version'];

      this.sending = true

      let formData = new FormData();
      formData.append('email', this.form.email);
      formData.append('content', this.form.content);
      formData.append('user_id', this.user.id);
      if (this.user.platform !== undefined && this.user.platform !== 'undefined') {
        formData.append('user_platform', this.user.platform);
      }
      if (this.user.platform_version !== undefined && this.user.platform_version !== 'undefined') {
        formData.append('user_app_version', this.user.platform_version);
      }

      let counter = 0;
      for (let file of this.files) {
        formData.append(`file${counter}`, file.file);
        counter++;
      }

      const response = await axios.post(
          `${config.BASE_API}${config.ROUTES.createIssue}?userId=${this.user.id}&rand=${this.user.rand}&session=${this.user.session}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
      );
      if (response?.data?.status) {
        if (response.data.status === 'fail') {
          this.validations.commonErrors.isError = true;
          this.validations.commonErrors.isBadResponse = true;

          return false;
        }
      }
      this.sending = false;
      this.clearForm();

      this.$emit('show:success:screen');
    },
    onFileChange(event) {
      this.validations.commonErrors.isError = false;
      this.validations.commonErrors.isFileTypeError = false;

      if (event.target.files.length !== 0) {
        if (!this.checkFileType(event.target.files[0].type)) {
          this.validations.commonErrors.isError = true;
          this.validations.commonErrors.isFileTypeError = true;

          return false;
        }

        const maxLength = 5;
        let name = event.target.files[0].name;
        if (name.length > maxLength) {
          let filenameParts = name.split(".");
          if (filenameParts.length > 0) {
            let ext = filenameParts[filenameParts.length - 1];
            name = name.substring(0, maxLength).concat(".. .").concat(ext);
          }
        }

        this.files.push({
          id: this.getUUIDv4(),
          name,
          size: event.target.files[0].size / 8,
          file: event.target.files[0],
        })
      }
    },
    checkFileType(fileType) {
      return fileType === "text/plain"
          || fileType === "image/gif"
          || fileType === "image/jpeg"
          || fileType === "image/png"
          || fileType === "application/zip"
          || fileType === "application/x-7z-compressed"
          || fileType === "application/pdf"
          || fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          || fileType === "application/msword"
          || fileType === "application/vnd.ms-excel"
          || fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    },
    deleteFile(id) {
      let deleteId = _.findIndex(this.files, _.find(this.files, {id}))
      if (deleteId !== -1) {
        this.files.splice(deleteId, 1)
        this.$forceUpdate();
      }
    },
    getUUIDv4() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
          (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
      );
    },
    onInputChange() {
      this.validations.isFormErrors = false;
      this.form.btnSendReady = !(this.form.email === '' || this.form.email === null || this.form.content === '' || this.form.content === null);
    },
  }
}
</script>

<style>
@font-face {
  font-family: "SFProText";
  src: url("../assets/fonts/SFProText.ttf");
}

input[type="file"] {
  display: none;
}

.feedback-form {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  width: 320px;
  padding: 24px 16px 16px;
  background-color: #ffffff;
}

.title {
  text-align: center;
  margin: 0 66px 0 67px;
  font-family: "SFProText", serif;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #20273d;
}

.desc {
  margin: 16px 0;
  font-family: "SFProText", serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: center;
  color: #777e96;
}

.form--field-group {
  margin-top: 16px;
}

.errors-block {
  margin-left: 10px;
  font-size: 12px;
  color: red;
}

.errors-block--content {
  margin-top: -5px;
}

.field-email {
  width: 288px;
  height: 72px;
  padding: 24px 16px 24px 16px;
  border-radius: 12px;
  border: solid 1px #d9dfe4;
  background-color: #f0f3f5;
  font-size: 14px;
}

.field-email::placeholder,
.field-content::placeholder {
  font-family: "SFProText", serif;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.41;
  letter-spacing: -0.41px;
  text-align: left;
  color: #777e96;
}

.field-email:focus,
.field-content:focus {
  border: none;
  outline: none;
}

.field-content {
  width: 288px;
  height: 112px;
  padding: 16px;
  border-radius: 12px;
  border: solid 1px #d9dfe4;
  background-color: #f0f3f5;
  font-size: 14px;
}

.file {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 288px;
  height: 48px;
  margin: 10px 0 8px;
  padding: 12px 12px 12px 16px;
  background-color: #f2f4f6;
  font-family: "SFProText", serif;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #448aff;
}

.file--filename {
  display: inline-block;
  margin-top: 3px;
}

.file--size {
  color: #777e96;
  margin-left: 3px;
  margin-top: 3px;
}

.iconapp-barclosesmall {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

.iconapp-barclosesmall:hover {
  cursor: pointer;
}

.field-attach {
  display: flex;
  flex-direction: row;
  width: 168px;
  height: 48px;
  margin: 16px 120px 0 0;
  padding: 12px 24px 12px 12px;
  background-color: #f0f3f5;
  border-radius: 4px;
}

.field-attach:hover {
  cursor: pointer;
}

.field-attach--icon {
  width: 24px;
  height: 24px;
  margin: 0 8px 0 0;
  object-fit: contain;
}

.field-attach--text {
  width: 100px;
  height: 20px;
  margin: 2px 0 2px 8px;
  font-family: "SFProText", serif;
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: -0.41px;
  text-align: left;
  color: #20273d;
}

.field-send {
  width: 288px;
  height: 64px;
  margin: 20px 0 0;
  padding: 20px 100px 24px;
  opacity: 0.5;
  background-color: #448aff;
  font-family: "SFProText", serif;
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: -0.41px;
  text-align: center;
  color: #ffffff;
  border-radius: 12px;
  border: none;
}

.field-send--ready {
  opacity: 1;
}

.field-send:hover {
  cursor: pointer;
}
</style>