<template>
  <div class="success-screen">
    <img class="success-screen--img" src="../assets/img/mail.svg" alt="mail">
    <h3 class="success-screen--title">Письмо отправлено!</h3>
    <span class="success-screen--desc">Мы свяжемся с вами в ближайшее время</span>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
@font-face {
  font-family: "SFProText";
  src: url("../assets/fonts/SFProText.ttf");
}

.success-screen {
  width: 320px;
  height: 640px;
  padding: 184px 16px 253px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.success-screen--img {
  width: 98px;
  height: 100px;
  margin: 0 95px 24px;
  object-fit: contain;
}

.success-screen--title {
  width: 218px;
  height: 25px;
  margin: 24px 35px 16px;
  font-family: "SFProText", serif;
  font-size: 21px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #20273d;
}

.success-screen--desc {
  width: 168px;
  height: 38px;
  margin: 16px 0 0;
  font-family: "SFProText", serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: center;
  color: #777e96;
}
</style>